<template>
  <v-container fluid class="pa-0 ma-0">
  <!-- <v-container fluid :class="$vuetify.breakpoint.xs ? 'pa-0' : 'pa-0'"> -->
    <v-toolbar dense flat>
      <v-btn text small class="mr-1"
        :disabled="datas.length === 0"
        :color="isAllCheck ? 'primary' : 'grey darken-1'"
        @click.stop="setAllCheck"
      >
        <v-icon
          small
          :color="isAllCheck ? 'primary' : 'grey darken-1'"
        >
          {{ isAllCheck ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
        </v-icon>
        전체
      </v-btn>
      <v-btn text small class="mr-1"
        color="success"
        :disabled="cIds.length === 0"
        @click.stop="setAlarmCheckMultiple"
      >
        <v-icon small>mdi-checkbox-marked-circle</v-icon>
        확인
      </v-btn>
      <v-btn text small class="mr-1"
        color="error"
        :disabled="cIds.length === 0"
        @click.stop="deleteChecked"
      >
        <v-icon small>mdi-trash-can-outline</v-icon>삭제
      </v-btn>
      <v-btn-toggle
        v-model="toggle_multiple"
        multiple
        dense
        tile
        borderless
        color="primary accent-3"
      >
        <v-btn
          v-model="search.today"
          text small class="px-1"
          @click="btnClick('today')"
        >
          <v-icon small
            :color="search.today ? 'primary' : ''"
          >mdi-calendar</v-icon>
          <span>오늘</span>
        </v-btn>
        <v-btn
          v-model="search.uncheck"
          text small class="px-1"
          @click="btnClick('uncheck')"
        >
          <v-icon small
            :color="search.uncheck ? 'warning' : ''"
          >mdi-checkbox-blank-circle</v-icon>
          <span>미확인</span>
        </v-btn>
      </v-btn-toggle>
      <!-- [정렬] 등록일순 -->
      <div class="ml-2 hidden-xs-only" style="width:120px">
        <v-select
          v-model="sort.default"
          :items="select.defaultSort"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectOrder"
        ></v-select>
      </div>
      <!-- 사용안함:[2021.8.4] 확인여부 -->
      <!-- <div class="hidden-xs-only" style="width:100px">
        <v-select
          v-model="search.ss1"
          :items="select.ss1"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectChange('ss1')"
        ></v-select>
      </div> -->

      <v-spacer></v-spacer>

      <!-- <v-btn text small class="hidden-xs-only">
        <v-icon small>mdi-download</v-icon>
        저장
      </v-btn> -->
      <!-- !! 사용안함: 검색 아이콘 처리 영역 start --
      <v-menu
        v-model="searchMenu"
        :close-on-content-click="false"
        :nudge-width="50"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text small class="hidden-xs-only">
            <v-icon small>mdi-magnify</v-icon>
            검색
          </v-btn>
        </template>
        <v-card
          max-width="550"
          min-width="550"
          tile
          elevation="0"
          class="ma-0 pa-0"
        >
          <v-toolbar dark color="primary" dense flat
            class="mb-0 pb-0"
          >
            <v-toolbar-items>
              <v-btn
                dark text class="orange--text text-h6 font-weight-bold" @click="searchPopBtn">검색</v-btn>
              <v-btn
                dark text class="white--text subheading" @click="searchMenu = false">취소</v-btn>
              <v-btn text icon
                @click="initVals"
              >
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                    >mdi-refresh</v-icon>
                  </template>
                  <span>초기화</span>
                </v-tooltip>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="mt-0 pt-0">
            <v-row
              align="center"
              justify="center"
              no-gutters
              class="ma-0 pa-0"
            >
              <v-col cols="12" xs="12" class="ma-0 pa-0">
                <v-row
                  no-gutters
                  align="center"
                  justify="center"
                >
                  <div style="width: 140px;">
                    <v-select
                      v-model="search.sf"
                      :items="select.sf"
                      label=""
                      item-value="value"
                      item-text="text"
                      hide-details
                      class="mt-3 mr-3"
                      dense
                      style="font-size: 0.785rem !important"
                    ></v-select>
                  </div>
                  <v-text-field
                    v-model="search.sw"
                    label="검색"
                    append-icon="mdi-magnify"
                    maxlength="20"
                    clearble
                    class="mt-5 mr-3"
                    @keyup.enter="searchPopBtn"
                  ></v-text-field>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
      !-- 검색 아이콘 처리 영역 end -->
    </v-toolbar>

    <v-toolbar dense flat color="grey lighten-2">
      <div class="text-left mr-0">
        <v-chip small color="primary">
          {{ totalItems }}
        </v-chip>
        <v-btn text icon
          @click="initVals"
        >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
              >mdi-refresh</v-icon>
            </template>
            <span>초기화</span>
          </v-tooltip>
        </v-btn>
      </div>
      <div class="text-left hidden-xs-only">
        <v-chip
          v-for="(item, i) in searchKeywords"
          :key="i"
          small
          :close="!item.isEver"
          color="grey lighten-4"
          class="mr-1"
          @click:close="closeSearchKeyword(item)"
        >{{ item.text }}</v-chip>
      </div>
      <v-spacer></v-spacer>
      <div style="width: 60px">
        <v-select
          :items="itemsPerPageOptions"
          v-model="options.itemsPerPage"
          dense
          class="mt-6"
        ></v-select>
      </div>
    </v-toolbar>

    <v-card :elevation="0">
      <v-data-table
        :headers="headers"
        :items="datas"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        hide-default-header
        hide-default-footer
        no-data-text="데이터가 없습니다"
        no-results-text="검색결과가 없습니다"
        loading-text="로딩중..."
      >
        <!-- 꼼수 : id 필드에 매칭시킨 item 을 가지고 각 row 마다 작업한다. -->
        <template v-slot:[`item.id`]="{ item }">
          <v-list two-line class="pa-0 ma-0">
            <v-list-item class="float-left">
              <v-list-item-content>
                <v-list-item-title>
                  <v-hover v-slot:default="{ hover }" transition="scale-transition">
                    <div
                      class="text-body-2 grey--text text--darken-1"
                    >
                      <v-icon
                        small left class="mr-2"
                        @click="setCheckItem(item)"
                        :color="item.isChecked ? 'primary' : 'grey darken-1'"
                      >
                        {{ item.isChecked ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                      </v-icon>
                      <v-icon
                        small
                        :color="item.isCheck === 0 ? 'warning' : 'success'"
                        class="mr-2"
                      >
                        {{ item.isCheck === 0 ? 'mdi-checkbox-blank-circle' : 'mdi-checkbox-marked-circle' }}
                      </v-icon>
                      <v-chip label color="primary" small class="mx-1 mb-1 px-1" outlined>
                        <span class="text-caption font-weight-bold">{{ typeName[item.pType] }} > {{ item.gubun1 }}</span>
                      </v-chip>
                      <span
                        class="text--primary"
                        style="cursor: pointer;font-size: 0.925rem;font-weight: 550;"
                        @click.stop="itemConfirm(item)"
                      >
                        {{ cutString(item.pInfo, 120) }}
                      </span>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-show="hover && item.isCheck === 0"
                            v-on="on"
                            small
                            color="success"
                            class="mx-3 pb-1"
                            @click="setAlarmCheck(item)"
                          >mdi-checkbox-marked-circle</v-icon>
                        </template>
                        <span>확인</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-show="hover"
                            v-on="on"
                            small
                            color="error"
                            class="pb-1"
                            @click="deleteAlarm(item)"
                          >mdi-trash-can-outline</v-icon>
                        </template>
                        <span>삭제</span>
                      </v-tooltip>
                    </div>
                  </v-hover>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <div class="py-0">
                    <!-- <span style="font-size: 0.785rem !important">
                       신청인: {{ item.fromName }} / 신청시간: {{ strDateFormat2(item.createdAt) }}
                    </span> -->
                    <span class="text-body-2">
                       {{ strDateFormat2(item.createdAt) }} {{ item.fromName }}
                    </span>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-data-table>

      <v-divider></v-divider>

      <div class="text-center pt-3 pb-3">
        <v-pagination
          v-model="options.page"
          :length="pages"
        ></v-pagination>
      </div>
    </v-card>

  </v-container>
</template>

<script>
import sleep from '@/lib/sleep'
import copy from 'copy-to-clipboard' // !! 클립보드 복사 플러그인

// @: filters
import cutString from '@/filters/cutString'
import strDateFormat2 from '@/filters/strDateFormat2'

export default {
  components: {},

  data: () => ({
    // 구분: 꼼수 - 헤더스에 별로 의미없는 1개만 매칭시킨다.
    headers: [
      {
        text: '',
        value: 'id',
        align: 'start', // !! left 가 아니라 start?
        sortable: false,
        width: '100%'
      }
    ],
    // 구분: 게시판용 변수들
    datas: [],
    totalItems: 0,
    itemsPerPageOptions: [ 15, 30, 50, 100 ],
    // 구분: v-model 과 params 로 백앤드로 전송되는 객체
    params: { // 검색용 인자 객체
      draw: 0,
      where: {},
      sort: [ 'createdAt' ], // 등록일순 정렬
      order: [ 'DESC' ],
      offset: 0,
      limit: 1
    },
    // 구분: 초기값 설정이 필요한 변수들
    options: { // v-data-table 에 의해 자동으로 기본값이 들어있는 객체.
      itemsPerPage: 15, // 초기값을 지정했다. 기본값은 15라 매뉴얼에 나옴
      page: 1
    },
    toggle_multiple: [], // v-btn-toggle 의 초깃값이 없는 상태
    loading: false,
    // 구분: 검색에 필요한 변수들 - 초기화 필요
    search: {
      sf: 1, // 검색어 검색 select 의 선택된 필드값
      sw: '', // 검색어 검색 text box input 값
      today: false, // 오늘 검색 버튼 클릭값 - true/false
      uncheck: false, // 미확인 검색 버튼 클릭값 - true/false
      ss1: '' // [확인여부] 셀렉트 검색 선택값
      // ss2: '' // [] 셀렉트 검색 선택값
    },
    // 구분: 정렬에 필요한 변수들 - 초기화 필요
    sort: {
      default: 1 // [등록일순]기본정렬 매칭 - 정렬의 기본값은 select.sort.value 로 결정
    },
    // 구분: 셀렉트 객체들
    select: {
      sf: [ // 검색어 검색 필드 셀렉트
        { text: '내용', value: 1 },
        { text: '등록자', value: 2 }
      ],
      ss1: [ // 확인여부 셀렉트
        { text: '확인여부', value: '' },
        { text: '확인함', value: 1 },
        { text: '확인안함', value: 2 }
      ],
      defaultSort: [ // 기본 정렬 셀렉트 - order 가 매칭되어있다.
        { text: '등록일순', value: 1, field: 'createdAt', order: 'DESC' },
        { text: '확인일순', value: 2, field: 'updatedAt', order: 'DESC' }
      ]
    },
    // 구분: 검색어 칩을 사용하는 것들의 배열 - 검색어 칩을 사용하는 요소를 명확히 알 수있다.
    // 타입: 정렬/검색 - sort/select
    // 이름: 요소이름 - sort.defalut
    // select: 비어있지 않은 경우 해당 셀렉트를 이용한다.
    // showSelect: 검색어 검색처럼 셀렉트를 직접 사용하진 않지만 텍스트를 보여줄 경우 사용(select: '' 인 경우에 주로 사용)
    // isEver: true 인 경우 항시 보여야 한다.
    // loading: true 인 경우 로딩시 처리된다
    useSearchKeywords: [
      { type: 'sort', name: 'default', select: 'defaultSort', showSelect: '', isEver: true, loading: true },
      { type: 'search', name: 'ss1', select: 'ss1', showSelect: '', isEver: false, loading: true },
      // { type: 'search', name: 'ss2', select: 'ss2', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'sw', select: '', showSelect: '', isEver: false, loading: false }
      // { type: 'search', name: 'sw', select: '', showSelect: 'sf', isEver: false, loading: false }
    ],
    // 구분: 검색어 칩 배열을 위한 변수
    searchKeywords: [],
    // 구분: 기타 변수들
    timeout: null, // delay() 에서 사용하는 변수 - 초기화 불필요
    mgTitle: { // 관리그룹의 제목. 카테고리에서 패칭해서 채운다. 관리그룹이 늘어나면 더 늘려야 한다.
      1: '',
      2: ''
    },
    // 구분: 검색 팝업을 위한 변수
    searchMenu: false,
    // 구분: 삭제등을 위해 리스트에서 선택(체크)된 아이디 목록
    cIds: [],
    // 구분: 전체 선택 여부
    isAllCheck: false,
    // 구분: type 에 따른 메뉴명
    typeName: {
      1: '소송',
      2: '자문',
      3: '인명부',
      4: '게시판',
      5: '전자결재',
      6: '자동완성키워드',
      7: '판례/법령',
      9: '회의실예약',
      45: '물품',
      46: '거래처',
      100: '공유관리'
    }
  }),

  computed: {
    setOffset () {
      if (!this.options.page) return 0
      return (this.options.page - 1) * this.options.itemsPerPage
    },
    setLimit () {
      return this.options.itemsPerPage
    },
    pages () { // 페이지네이션 객체에 쓰인다
      if (this.options.itemsPerPage == null || this.totalItems == null) {
        return 0
      }
      return Math.ceil(this.totalItems / this.options.itemsPerPage)
    }
  },

  mounted () {
    // 중요: 정상적으로 로그인하지 않으면 콘솔에 에러가 나는데.. 이 에러는 오히려 콘솔창에 생기라고 놔둬야 한다!
    // 이미 router.js 에서 로그인하지 않은경우 처리하므로 다시 '/' 로 뺄 필요도 없다..
    if (!this.$store.state.ui.dbcode) {
      // this.redirect('/')
    }

    // 중요: 로딩시 등장해야할 검색어 칩을 찾아서 띄운다. 전돨되는 값은 배열이다.
    this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))
  },

  // 자동감지하고 리스트 재패칭 - 즉각적인 반영이 안될시 delay() 를 써야한다!
  watch: {
    '$route' (to, from) {
      // 내부등록시 리프레시 로직
      if (to.params.id) {
        const paramIds = to.params.id.split('-')
        if (paramIds.length > 1 && paramIds[1] === 'R') {
          // !! 등록
          // 등록시엔 '-R' 이 붙어온다. 이를 통해 리스트 초기화를 시킨다.
          this.initVals().then(() => {
            this.$router.push(`/member/alarm`) // 리스트로 다시 보낸다.
          })
        } else if (paramIds[0] === 'LR') {
          // 주의: 삭제
          // 리스트 리프레시로 파라미터가 날아온 경우 > 삭제의 경우
          this.options.page = 1 // 1페이지로 이동
          this.delay(50) // 리프레시 후
          this.$router.push(`/member/alarm`) // 리스트로 다시 보낸다.
        }
      }
    },
    // 중요: mounted 에서 리스트를 부를 필요없이 이것만으로 초기 로딩이 된다!
    options: {
      handler () {
        this.list()
      },
      deep: true
    },
    // 사용안함: 차후 자동완성제안에 사용할 것임
    // 'params.search': { // 검색어 자동 감지
    //   handler () {
    //     this.options.page = 1 // 검색어 검색시 1페이지로 이동하게 함
    //     this.delay() // 키보드 눌림에 대한 약간의 딜레이를 준다
    //   }
    // },
    'options.itemsPerPage': { // 페이징 갯수 변경 자동감지
      handler () {
        this.options.page = 1 // 1페이지로 이동
        this.params.offset = 0 // 옵셋을 초기화 하지 않으면 에러
        this.delay(50)
      }
    }
  },

  methods: {
    cutString,
    strDateFormat2,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    initialize () {
      this.list()
    },
    // 참고: watch 로 검색시 약간의 딜레이를 줘야 한다.
    delay (ms = 800) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.list()
      }, ms)
    },
    // 중요: 등록시 검색등에 쓰이는 변수 초기화하고 리스트 재패칭
    async initVals () {
      this.doInit().then(() => {
        this.delay(100)
      })
    },
    // 변수 초기화 실행
    doInit () {
      return new Promise((resolve, reject) => {
        // 구분: params 로 백앤드로 전송되는 값
        this.params.draw = 0
        this.params.where = {}
        this.params.sort = [ 'createdAt' ]
        this.params.order = [ 'DESC' ]
        this.params.offset = 0
        this.params.limit = 1

        // 구분: 검색용(v-model) 변수 - 초기값 설정이 필요
        this.search.sf = 1 // 검색어 검색의 선택된 필드값
        this.search.sw = '' // 검색어 검색의 text box input 값
        this.search.today = false // 오늘 검색 버튼 클릭값 - true/false
        this.search.uncheck = false // 미확인 검색 버튼 클릭값 - true/false
        this.search.ss1 = '' // 확인여부 셀렉트 선택값
        // this.search.ss2 = '' // 거래형태 셀렉트 선택값
        // this.search.ss3 = '' // 정보위치 셀렉트 선택값
        // this.search.ss4 = '' // 업무유형 셀렉트 선택값

        // 구분: 정렬 기본값 매칭(갯수만큼)
        this.sort.default = 1

        // 구분: 기타 초기값 설정이 필요한 변수들
        this.datas = []
        this.totalItems = 0
        this.options.itemsPerPage = 15
        this.options.page = 1
        this.toggle_multiple = []
        this.loading = false

        // 구분: 검색어 칩 배열도 초기화
        this.searchKeywords = []
        // 그리고 초기 검색어 칩을 다시 띄움
        this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))

        // 구분: 선택요소 초기화
        this.cIds = []

        // 구분: 전체선택 초기화
        this.isAllCheck = false

        resolve(true)
      })
    },
    // 사용안함: 구분: 관리자/물품구매/회의실관리 등의 관리권한이 있는지 체크
    // async getAuthCheck () {
    //   try {
    //     const { data } = await this.$axios.get(`lawork/lwc/getAllTeamIds`)
    //     if (!data.success) this.sbpop(`오류가 발생하였습니다: ${data.message}`)
    //     console.log(data.auths)
    //     return data.auths
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // !! 리스트 패칭
    async list () {
      try {
        if (this.loading) return
        this.loading = true

        this.params.draw++
        this.params.offset = this.setOffset
        this.params.limit = this.setLimit

        // !! 검색용 객체 만들기 - where 의 값이 없으면 삭제한다.
        const ws = this.params.where
        for (let key in ws) {
          if (!ws[key]) {
            delete ws[key]
          }
        }
        // console.log(this.params)

        // !! 부드러운 로딩을 위해 ... 임의의 시간 딜레이를 두고 실행
        await sleep(500 - Math.floor(Math.random() * 300))

        const { data } = await this.$axios.get(`lawork/member/alarmList`, { params: this.params })
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        // 총 검색 갯수(이게 주어져야 페이징이 된다)
        this.totalItems = data.totalItems

        // 참고: 리스트 데이터 반영
        this.datas = data.list
        // if (data.list.length > 0) {
        //   data.list.forEach(item => {
        //   })
        // }

        this.loading = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: -- 검색처리 메소드 모음
    // 팝업 검색 버튼 클릭 이벤트 핸들러
    async searchPopBtn () {
      try {
        // 1) 검색어 검색 처리
        await this.searchWord()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [검색] - true/false 검색 버튼 처리 함수
    async btnClick (elem) {
      try {
        this.search[elem] = !this.search[elem]
        this.params.where[elem] = this.search[elem]

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [검색] - 셀렉트 검색 처리 메소드
    // 주의: 소송분야 상세(ss3) 때문에 약간 변형됨
    async selectChange (elem) {
      try {
        this.params.where[elem] = this.search[elem]

        // !! 검색어 칩 처리 - 타입은 검색 - search && name = elem
        const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === elem)
        await this.setSearchKeywords(kw)

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [검색] - 검색어 검색 처리 함수
    async searchWord () {
      try {
        if (this.search.sw.length > 0) { // 검색어가 있으면 파라미터에 넣고
          this.params.where.sf = this.search.sf
          this.params.where.sw = this.search.sw
        } else { // 없어도 일단 넣지만 값을 비운다. list() 에서 알아서 삭제된다.
          this.params.where.sf = ''
          this.params.where.sw = ''
        }

        // !! 검색어 칩 처리 - type = search  && name = sw
        const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === 'sw')
        await this.setSearchKeywords(kw)

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 정렬처리 메소드 모음 ----
    // this.select.{가변이름}Sort 로 여러개의 소트를 처리할 수 있다.
    // 여러개의 소트가 있어도 처리 가능하다
    // this.params.sort 를 초기화 하고 모든 소트를 새로 만든다.
    async selectOrder () {
      try {
        // 초기화
        this.params.sort = []
        this.params.order = []

        for (let key in this.sort) {
          const selectSortValue = this.sort[key]
          const field = this.select[`${key}Sort`].filter(c => c.value === selectSortValue)[0].field
          const order = this.select[`${key}Sort`].filter(c => c.value === selectSortValue)[0].order

          this.params.sort.push(field)
          this.params.order.push(order)

          // 검색어 칩 - type = sort(정렬)  && name = elem
          const kw = this.useSearchKeywords.find(k => k.type === 'sort' && k.name === key)
          await this.setSearchKeywords(kw)
        }

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: -- 검색어칩 처리 메소드 모음
    // 로딩시 보여줄 검색어 칩을 처리하는 메서드
    async loadSearchKeywords (kw) {
      try {
        if (!Array.isArray(kw)) throw new Error('잘못된 변수전달 방식입니다.')
        kw.forEach(async (k) => {
          await this.setSearchKeywords(k)
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 중요: 검색어 칩을 보여주는 처리를 하는 메서드
    async setSearchKeywords (kw) {
      try {
        // this.searchKeywords 배열에 등록될 객체의 뼈대
        let skw = { text: '', type: kw.type, name: kw.name, isEver: kw.isEver }

        // 기존 같은 타입과 이름의 배열이 있으면 삭제
        const index = this.searchKeywords.findIndex(k => k.type === kw.type && k.name === kw.name)
        if (index > -1) {
          this.searchKeywords.splice(index, 1)
        }

        // 현재값
        const currVal = this[kw.type][kw.name] || ''

        // select 가 있으면 select 에서 보여줄 text 를 가져온다
        if (kw.select) {
          const sel = this.select[kw.select].find(k => k.value === currVal)
          skw.text = (sel.value) ? sel.text : ''
        } else {
          // select 가 아닌 text 입력값은 현재값을 바로 매칭한다.
          // showSelect 가 지정된 경우 해당 셀렉트의 text 를 보여준다.
          if (kw.showSelect) {
            if (currVal) { // 값이 있어야 넣어준다
              skw.text = `${this.select[kw.showSelect].find(k => k.value === this.search[kw.showSelect]).text} - "${currVal}"`
            } else {
              skw.text = ''
            }
          } else {
            if (currVal) { // 값이 있어야 넣어준다
              skw.text = `"${currVal}"`
            } else {
              skw.text = ''
            }
          }
        }

        if (skw.text) {
          this.searchKeywords.push(skw)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 검색어 칩을 닫는 메서드
    async closeSearchKeyword (chip) {
      try {
        if (!chip.isEver) { // isEver = true 인 넘은 없앨 수 없다. false 인 경우만 처리
          const kw = this.useSearchKeywords.find(c => c.type === chip.type && c.name === chip.name)
          if (kw.select) {
            // 셀렉트 검색인 경우
            // this.select.sido = '' 처럼 셀렉트의 가장 처음값을 초기값으로 보고 변경시킨다.
            this[kw.type][kw.name] = this.select[kw.name][0].value
            await this.selectChange(kw.name)
          } else {
            //  검색어 검색인 경우
            if (kw.type === 'search' && kw.name === 'sw') {
              this[kw.type][kw.name] = ''
              await this.searchWord()
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 확인하고 페이지 이동
    async itemConfirm (item) {
      try {
        // console.log(item)
        // 확인하지 않은 경우 확인으로 만들어 준다.
        if (!item.isCheck) {
          const { data } = await this.$axios.get(`lawork/member/setAlarmConfirm/${item.id}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        }

        // 고쳐: 해당 부모의 리스트나 상세 페이지로 이동시킨다 - 계속 추가되겠지?
        if (item.pType === 1) {
          // 소송 - 2021.8.5 추가
          this.$router.push(`/case/${item.pId}`)
          //
        } else if (item.pType === 2) {
          // 자문 - 2021.8.5 추가
          this.$router.push(`/advice/${item.pId}`)
          //
        } else if (item.pType === 3) {
          // 인명부 - 2021.8.5 추가
          this.$router.push(`/client/${item.pId}`)
          //
        } else if (item.pType === 4) {
          // 게시판 - 2021.7.14 추가
          this.$router.push(`/bbs/${item.pId}`)
          //
        } else if (item.pType === 5) {
          // 전자결재인 경우 - 해당 상세로 바로 직행
          this.$router.push(`/ealist/${item.pId}`)
          //
        } else if (item.pType === 45) {
          // 물품구매신청인 경우(물품관리팀만 해당) 구매신청 페이지로 이동시킨다.
          this.$router.push(`/requestList`)
          //
        } else if (item.pType === 100) {
          // !! 공유요청인 경우(관리자팀만 해당) 공유관리 페이지로 이동시킨다.
          if (!item.fromEmail) throw new Error(`오류가 발생하였습니다: 공유요청 이메일 주소가 없습니다.`)

          // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
          const target = 'confirmDialog'
          const pop = await this.findParentRefs(this.$parent, target)
          if (!pop) throw new Error('팝업창을 열 수 없습니다.')
          // 찾았으면 팝업을 연다
          const msg = `공유관리로 이동하시겠습니까? 이동 후<br><b>${item.fromEmail}</b>은 자동으로 클립보드에 저장됩니다.<br>'공유팝업' 화면에서 붙여넣기 하시면 됩니다.`
          if (await pop.open('페이지 이동', msg, { color: 'info', width: 400 })) {
            await copy(`${item.fromEmail}`)
            this.$store.commit('SB_POP', { msg: '클립보드에 복사되었습니다.', color: 'success' })
            this.$router.push(`/admin/shareManage`)
          }
          //
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 리스트의 체크박스를 클릭할때 이벤트 핸들러
    // 체크 유무에 따라 this.cIds 배열에 넣고 빼는 단순한 함수
    async setCheckItem (item) {
      try {
        item.isChecked = !item.isChecked
        if (item.isChecked) { // #1. 체크를 할 때
          if (!this.cIds.includes(item.id)) { // 배열에 안들어있으면 아이디를 넣자
            this.cIds.push(item.id)

            if (this.cIds.length === this.datas.length) {
              // 선택한 넘이 전체와 같은 길이라면 전체선택임
              this.isAllCheck = true
            }
          }
        } else { // #2. 체크를 풀때
          const idx = this.cIds.indexOf(item.id)
          this.cIds.splice(idx, 1)

          this.isAllCheck = false // 전체 선택을 풀자
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: [2021.8.4] 전체선택/해제
    async setAllCheck () {
      try {
        this.isAllCheck = !this.isAllCheck

        this.cIds = [] // 비운다.
        if (this.isAllCheck) { // #1 전체선택
          this.datas.map(item => {
            item.isChecked = true
            this.cIds.push(item.id)
          })
        } else { // #2 전체해제
          this.datas.forEach(item => {
            item.isChecked = false
          })
          // this.cIds 는 위에서 이미 비웠다.
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: [2021.8.4] 선택된 1개를 확인으로 변경
    async setAlarmCheck (item) {
      try {
        // 아이디를 배열에 담아서 처리한다.
        const ids = [item.id]
        const { data } = await this.$axios.post(`lawork/member/setAlarmCheckMultiple`, { ids })
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        item.isCheck = 1
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: [2021.8.4] 복수선택된 것을 확인으로 변경
    async setAlarmCheckMultiple () {
      try {
        // console.log(this.cIds) // 선택한 넘의 ids
        // 선택한 넘 && 확인하지 않은넘의 id 만 간추려서 배열로 만든다.
        const items = this.datas.filter(item => item.isChecked && item.isCheck === 0)
        const ids = items.map(item => item.id)
        if (ids.length > 0) { // #1. 확인할 대상이 있다.
          //
          const { data } = await this.$axios.post(`lawork/member/setAlarmCheckMultiple`, { ids })
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          items.map(item => {
            item.isChecked = false
            item.isCheck = 1
          })
          this.cIds = [] // 아이템 선택 초기화를 잊지말것
          this.isAllCheck = false
          // await this.list() // 리스트 리프레시
          //
        } else { // #2. 확인할 대상이 없다.
          const target = 'confirmDialog'
          const pop = await this.findParentRefs(this.$parent, target)
          if (!pop) throw new Error('팝업창을 열 수 없습니다.')
          // 찾았으면 팝업을 연다
          const msg = `확인할 알림이 없습니다.`
          await pop.open('확인', msg, { color: 'warning', width: 400 })
          //
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 1개의 알림 삭제
    async deleteAlarm (item) {
      try {
        // console.log(item.id)
        if (!item) throw new Error(`잘못된 요청입니다.`)

        // 주의: 삭제할 알람 아이디 1개를 배열로 넘긴다
        const ids = [ item.id ]

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          const { data } = await this.$axios.post(`lawork/member/deleteAlarm`, { ids })
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          this.cIds = [] // 아이템 선택 초기화를 잊지말것!!
          await this.list() // 리스트 리프레시
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 선택된 알람 일괄 삭제
    async deleteChecked () {
      try {
        if (this.cIds.length <= 0) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          const { data } = await this.$axios.post(`lawork/member/deleteAlarm`, { ids: this.cIds })
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          this.cIds = [] // 아이템 선택 초기화를 잊지말것!!
          await this.list() // 리스트 리프레시
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 5px;
  margin-right: 0px;
}
.v-overflow-btn .v-input__append-inner {
  width: 30px;
}
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
    text-align: left;
}
/*
  참고: 모든 vue2editor 뷰어에 공통적용
*/
#vue2editorViewer p {
  margin-bottom: 0px !important;
}
#vue2editorViewer2 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
#vue2editorViewer3 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
</style>
